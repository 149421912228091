<template>
	<el-dialog
		@close="close"
		:before-close="closeBefore"
		:close-on-click-modal="false"
		v-model="visible"
		:title="title"
		:width="width + 100"
		custom-class="custom-dialog"
	>
		<div class="dialog" v-loading="loading">
			<div class="custom_form">
				<el-form ref="currentFromRef" :model="currentFrom" label-width="auto" class="custom-form" status-icon>
					<el-form-item v-for="item in fromItems" :key="item.prop" :label="item.label" :prop="item.prop" :rules="item.formConfig.rules">
						<el-input v-if="item.formConfig.type === 'input'" v-model="currentFrom[item.prop]" v-bind="item.formConfig.attrs"></el-input>
						<el-switch
							v-if="item.formConfig.type === 'switch'"
							v-model="currentFrom[item.prop]"
							active-color="#848484"
							inactive-color="#B8B8B8"
							v-bind="item.switch || item.formConfig.attrs"
						/>
					</el-form-item>
				</el-form>
			</div>
			<div class="dialog-footer">
				<div class="custom-btn shadow medium" @click="handleCancel">取消</div>
				<div class="custom-btn active medium" @click="handleConfirm">确认</div>
			</div>
		</div>
	</el-dialog>
</template>
<script>
import { getCurrentInstance, reactive, onMounted, ref, nextTick } from 'vue';
import { cloneDeep } from 'lodash';
import { ElMessage } from 'element-plus';
export default {
	name: 'Setting',
	components: {},
	props: {
		columns: {
			type: Array,
			default: () => {
				return [];
			},
		},
	},
	computed: {
		fromItems() {
			return this.columns.filter((e) => {
				return e.formConfig;
			});
		},
		defaultForm() {
			return this.columns.reduce((pre, cur) => {
				if (cur.formConfig) {
					pre[cur.prop] = cur.formConfig.defaultValue;
				}
				return pre;
			}, {});
		},
	},
	emits: ['confirm'],
	setup(props, { emit }) {
		const { proxy } = getCurrentInstance();
		const visible = ref(false);
		const title = ref('');
		const width = ref(500);
		const formConfig = ref({});
		const currentFrom = ref({}); //当前编辑的对象
		const loading = ref(false);
		let confirmCallback = () => {};
		const show = (config, row) => {
			title.value = config.title || '';
			width.value = config.width || 400;
			visible.value = true;
			formConfig.value = config || {};
			if (row) {
				currentFrom.value = cloneDeep(row || {});
			} else {
				currentFrom.value = cloneDeep(proxy.defaultForm);
			}
		};
		const handleCancel = () => {
			visible.value = false;
		};
		const closeBefore = (done) => {
			done();
		};
		const close = () => {
			nextTick(() => {
				proxy.$refs.currentFromRef?.clearValidate();
				confirmCallback = null;
				setTimeout(() => {}, 300);
			});
		};
		const handleConfirm = () => {
			proxy.$refs.currentFromRef.validate(async (valid) => {
				if (valid) {
					const { method = '$post', url, formatFrom } = formConfig.value;
					let params = currentFrom.value;
					if (!url) return;
					if (formatFrom) {
						params = formatFrom(currentFrom.value);
					}
					try {
						loading.value = true;
						await proxy[method](url, params).then(async (res) => {
							if (res.state == 200) {
								emit('confirm', { config: formConfig.value, form: currentFrom.value });
								ElMessage.success(res.msg);
							} else {
								ElMessage.error(res.msg);
							}
						});
					} finally {
						loading.value = false;
					}

					visible.value = false;
				}
			});
		};
		//获取消息提示设置，在用户登录页面写完时将此方法移至登录页面
		onMounted(() => {});
		return {
			visible,
			width,
			title,
			currentFrom,
			show,
			close,
			closeBefore,
			handleCancel,
			handleConfirm,
			loading,
		};
	},
};
</script>
<style lang="scss"></style>
