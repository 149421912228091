<template>
	<div class="table_container">
		<div class="top_header" v-if="title">
			<p class="header_title">{{ title }}</p>
		</div>
		<div class="top_group" v-if="topBtn.length">
			<div class="custom-btn small shadow" v-for="(item, index) in topBtn" :key="index" @click="handle_btn({ config: item })">{{ item.name }}</div>
		</div>
		<slot name="top"></slot>
		<div class="table">
			<el-table :height="height" ref="tableRef" row-key="date" :data="tableData" style="width: 100%" v-bind="$attrs" v-loading="loading">
				<template v-for="item in columns" :key="item.prop">
					<el-table-column type="index" width="80" align="center" label="序号" v-if="item.type === 'sort'" v-bind="item.attrs" />
					<el-table-column :prop="item.prop" :label="item.label" align="center" v-else-if="item.type === 'switch'" v-bind="item.attrs">
						<template #default="scope">
							<el-switch
								v-model="scope.row[item.prop]"
								active-color="#848484"
								inactive-color="#B8B8B8"
								v-bind="item.switch"
								@change="
									(e) =>
										switch_change({
											config: item,
											index: scope.$index,
											row: scope.row,
											value: e,
										})
								"
							/>
						</template>
					</el-table-column>
					<el-table-column align="center" v-else-if="item.type === 'operate'" :label="item.label" v-bind="item.attrs">
						<template #default="scope">
							<div class="btn_group">
								<template v-for="btn in item.btn_group" :key="btn.name">
									<el-popconfirm
										popper-class="custom-popper"
										trigger="click"
										placement="top"
										:title="btn.popover.title"
										:width="btn.popover.width || 190"
										hide-icon
										@confirm="
											handle_btn({
												config: btn,
												index: scope.$index,
												row: scope.row,
											})
										"
										v-if="btn.popover"
									>
										<template #reference>
											<div class="custom-btn small shadow" :class="btn.className">{{ btn.name }}</div>
										</template>
									</el-popconfirm>
									<div
										class="custom-btn small shadow"
										:class="btn.className"
										@click="
											handle_btn({
												config: btn,
												index: scope.$index,
												row: scope.row,
											})
										"
										v-else
									>
										{{ btn.name }}
									</div>
								</template>
							</div>
						</template>
					</el-table-column>
					<el-table-column align="center" :prop="item.prop" :label="item.label" v-bind="item.attrs" v-else />
				</template>
			</el-table>
			<div class="pagination" v-if="isPage">
				<el-pagination
					size="small"
					background
					layout="prev, pager, next"
					:current-page="page.current"
					:page-size="page.pageSize"
					:total="page.total"
					@current-change="pageChange"
				/>
			</div>
		</div>
		<CustomForm :columns="columns" ref="customForm" @confirm="confirm"></CustomForm>
	</div>
</template>
<script>
import { getCurrentInstance, reactive, onMounted, ref } from 'vue';
import { ElMessage } from 'element-plus';
import CustomForm from './CustomForm.vue';
export default {
	name: 'Setting',
	components: { CustomForm },
	props: {
		// 表格标题
		title: {
			type: String,
			default: '',
		},
		// 顶部按钮组
		topBtn: {
			type: Array,
			default: () => {
				return [];
			},
		},
		// 表格列表配置项
		columns: {
			type: Array,
			default: () => {
				return [];
			},
		},
		// 请求地址
		api_url: {
			type: String,
			default: '',
		},
		// 表格高度
		height: {
			type: [Number, String],
			default: 500,
		},
		// 是否初始化表格
		initData: {
			type: Boolean,
			default: true,
		},
		// 筛选条件
		filter_params: {
			type: Object,
			default: () => {
				return {};
			},
		},
		// 是否为分页列表
		isPage: {
			type: Boolean,
			default: true,
		},
		callback: {
			type: Function,
			default: null,
		},
	},
	watch: {
		filter_params: {
			handler(newValue, oldValue) {
				this.getData(true);
			},
			deep: true,
		},
	},
	setup(props) {
		const { proxy } = getCurrentInstance();
		const loading = ref(false);
		const page = reactive({
			//页数
			current: 1,
			pageSize: 10,
			total: 0,
		});
		let tableData = ref([]);
		const getData = async (isRefresh) => {
			if (isRefresh) page.current = 1;
			if (!props.api_url) return;
			loading.value = true;
			const params = {
				...props.filter_params,
				current: page.current,
				pageSize: page.pageSize,
			};
			try {
				await proxy.$get(props.api_url, params).then((res) => {
					if (res.state == 200) {
						props.callback && props.callback(res);
						if (props.isPage) {
							tableData.value = res.data.list;
							page.total = +res.data.pagination.total;
							page.current = +res.data.pagination.current;
						} else {
							tableData.value = res.data;
						}
					}
				});
				loading.value = false;
			} catch (error) {
				loading.value = false;
			}
		};

		const pageChange = (e) => {
			page.current = e;
			getData();
		};
		const switch_change = async ({ config, index, row, value }) => {
			config.callback && (await config.callback(index, row, value));
			if (!config.noRefresh) {
				getData();
			}
		};
		// 按钮气泡框显隐
		const handle_btn = async ({ config, index, row, value }) => {
			if (config.type === 'edit' || config.type === 'add') {
				proxy.$refs.customForm.show(config, row);
			} else {
				config.callback && (await config.callback(index, row, value));
				if (!config.noRefresh) {
					getData();
				}
			}
		};
		// 保存表单
		const confirm = async ({ config, form }) => {
			if (!config.noRefresh) {
				getData();
			}
		};
		//获取消息提示设置，在用户登录页面写完时将此方法移至登录页面
		onMounted(() => {
			if (props.initData !== false) {
				getData();
			}
		});
		return {
			tableData,
			handle_btn,
			switch_change,
			loading,
			confirm,
			pageChange,
			page,
			getData,
		};
	},
};
</script>
<style lang="scss" scoped>
.table_container {
	border-radius: 10px;
	background: #fafafa;
	border: 1px solid #b8b8b8;
	box-shadow: 2px 6px 12px 0px rgba(143, 141, 143, 0.3);
	overflow: hidden;
}
.top_header {
	height: 40px;
	line-height: 40px;
	background: #eeeeee;
	padding-left: 23px;
	font-size: 14px;
}
.top_group {
	background-color: #fff;
	padding: 10px;
}
.btn_group {
	display: flex;
	padding: 3px 0;
	justify-content: space-between;
}
:deep(.pagination) {
	height: 37px;
	background-color: #fff;
	display: flex;
	justify-content: flex-end;
	padding: 4px 0;
	.btn-prev,
	.btn-next,
	.number {
		transform: scale(0.85);
		padding: 3px;
		margin: 0 1px;
		font-size: 14px;
		line-height: 25px;
		height: 25px;
		min-width: 25px !important;
	}
}
</style>
