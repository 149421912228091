<template>
	<div class="common-layout">
		<el-container :style="{ minWidth: `${clientWidth - 150}px` }" id="elContainer">
			<el-aside style="width: 200px">
				<div class="aside-title">
					<span>{{ storeName }}</span>
				</div>
				<router-link v-for="link in navLinks" :key="link.path" :to="{ path: link.path }" class="sld_left_nav_item">
					<span class="text">{{ link.text }}</span>
				</router-link>
				<div class="aside-foot">
					<div class="custom-btn" @click="pageClose">退出</div>
				</div>
			</el-aside>
			<el-main>
				<router-view v-slot="{ Component }">
					<!-- <keep-alive :include="['chatPage', 'chatHistory', 'studioPage']"> -->
					<component :is="Component" :key="$route.path" @getCurMemberId="getCurMember" />
					<!-- </keep-alive> -->
				</router-view>
			</el-main>
		</el-container>
	</div>
</template>

<script>
import { ref, reactive, getCurrentInstance, onMounted, onUnmounted, computed, provide, h } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { storeUrl, identity } from '@/utils/config';
import canAutoPlay from 'can-autoplay';
import { ElMessageBox } from 'element-plus';

export default {
	name: 'Home',
	beforeCreate() {
		this.$socket.emit('connect_success', this.connectBaseData);
		this.$socket.emit('connect_store_success', this.connectBaseData);
		this.sockets.subscribe('contact_change', this.socketContact);
		this.sockets.subscribe('contact_store_change', this.socketContact);
	},
	setup() {
		const store = useStore();
		const { proxy } = getCurrentInstance();
		const imLogo = require('@/assets/im_logo.png');
		const router = useRouter();
		const adminName = ref(localStorage.adminName);
		if (identity === 'admin') {
			setTimeout(() => {
				adminName.value = localStorage.adminName;
			}, 300);
		}
		const storeInfo = reactive({ data: {} });
		const adminInfo = reactive({ data: {} });
		const clientWidth = ref(0);
		const L = proxy.$getCurLanguage();
		const curMemberInfo = ref({});
		const connectBaseData = {
			// TODO【MD】seller
			storeId: identity == 'seller' ? localStorage.storeId : 0,
			userId: identity == 'seller' ? localStorage.vendorId || 1 : localStorage.adminId || 1,
			role: 2,
		};
		// 计算属性
		const getTitle = computed(() => {
			return identity === 'seller' ? L['商户-客服助手'] : L['平台-客服助手'];
		});

		const storeName = computed(() => {
			return identity === 'seller' ? storeInfo.data.vendorName : adminName.value;
		});

		const showBackBtn = computed(() => {
			return Boolean(identity === 'admin' || (identity === 'seller' && isStoreAdmin));
		});

		const isStoreAdmin = computed(() => {
			return Boolean(identity == 'seller' && localStorage.isStoreAdmin && JSON.parse(localStorage.isStoreAdmin));
		});

		const isAdminSuper = computed(() => {
			return Boolean(identity == 'admin' && localStorage.isSuper && JSON.parse(localStorage.isSuper));
		});
		provide('adminInfo', adminInfo.data);
		provide('storeInfo', storeInfo);
		const adminLinks = [
			{ path: '/service', text: '用户聊天' },
			{ path: '/studio', text: '工作室聊天' },
			{ path: '/history', text: '聊天记录' },
			{ path: '/autoReply', text: '便捷用语' },
			{ path: '/setting', text: '聊天设置' },
			// 其他路由链接
		];
		const sellerLinks = [
			{ path: '/service', text: '聊天' },
			{ path: '/history', text: '聊天记录' },
			{ path: '/autoReply', text: '便捷用语' },
			{ path: '/setting', text: '聊天设置' },
			// 其他路由链接
		];
		const navLinks = computed(() => {
			let filteredLinks = identity === 'admin' ? adminLinks : sellerLinks;
			if (!isAdminSuper.value) {
				// 如果不是超级管理员，过滤掉'/studio'链接
				filteredLinks = filteredLinks.filter((link) => link.path !== '/studio');
			}
			if (!isAdminSuper.value && !isStoreAdmin.value) {
				// 如果既不是超级管理员也不是店面管理员，进一步过滤掉'/history'链接
				filteredLinks = filteredLinks.filter((link) => link.path !== '/history');
			}
			return filteredLinks;
		});
		// 其他方法
		//退出登录功能
		const loginOut = () => {
			//调用商户的退出登录接口
			let sld_refresh_token = localStorage.getItem('sld_refresh_token') || localStorage.getItem('sld_token');
			proxy
				.$post(`v3/${identity}Login/oauth/logout`, {
					grant_type: 'refresh_token',
					refresh_token: sld_refresh_token,
				})
				.then((res) => {
					if (res.state == 200) {
						console.log(res);
						localStorage.clear();
						window.location.replace(storeUrl + '/user/login?flag=exit');
					}
				});
		};
		const goHome = () => {
			router.push({
				path: '/service',
			});
		};
		const socketContact = (e) => {
			e = formatObj(e);
			if (e.userType === 1 && curMemberInfo.memberId !== e.memberId) {
				document.title = '有一条新消息';
				if (Boolean(localStorage.msgVoiceSetting)) {
					play();
				}
			} else {
				document.title = '客服';
			}
		};
		// 格式化对象
		const formatObj = (e) => {
			// memberAvatar，memberId左侧
			// vendorAvatar，vendorId 右侧
			if (e.platformId) {
				// 1为平台客服,2为工作室客服
				if (identity == 'admin') {
					// 如果是平台管理员，根据userType改变用户类型和其他属性
					if (e.userType === 1) {
						e.userType = 2;
					} else {
						e.userType = 1;
					}
					e.memberAvatar = e.vendorAvatar;
					e.memberName = e.storeName;
					e.memberId = e.vendorId;
					e.vendorAvatar = e.platformAvatar;
					e.vendorId = e.platformId;
				} else {
					e.memberAvatar = e.platformAvatar;
					e.memberId = e.platformId;
				}
			}
			return e;
		};

		//获取店铺的基本信息
		const getStoreBaseInfo = () => {
			proxy.$get('v3/seller/seller/store/indexStoreInfor').then((res) => {
				if (res.state == 200) {
					storeInfo.data = res.data;
					store.commit('updateStoreInfo', res.data);
				}
			});
		};
		//获取声音的设置
		const getVoiceSetting = () => {
			proxy.$post(`v3/helpdesk/${identity}/sound/setting/isOpen`).then((res) => {
				if (res.data) {
					store.commit('updateMsgVoiceSetting', res.data.isOpen ? true : false);
				}
			});
		};

		const pageClose = () => {
			if (navigator.userAgent.indexOf('MSIE') > 0) {
				if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
					window.opener = null;
					window.close();
				} else {
					window.open('', '_top');
					window.top.close();
				}
			} else if (navigator.userAgent.indexOf('Firefox') > 0) {
				window.location.href = 'about:blank ';
			} else {
				window.opener = null;
				window.open('', '_self', '');
				window.close();
			}
		};
		const getAdminInfo = (isSeller) => {
			const url = isSeller ? 'v3/system/seller/setting/getSettingList' : 'v3/system/admin/setting/getSettingList';
			proxy
				.$get(url, {
					str: 'platform_customer_service_name,platform_customer_service_logo',
				})
				.then((res) => {
					if (res.state == 200) {
						adminInfo.data.adminName = res.data[0].value;
						adminInfo.data.admin_logo = res.data[1].imageUrl;
						localStorage.setItem('admin_logo', res.data[1].imageUrl);
					}
				});
		};
		const getCurMember = (memberInfo) => {
			curMemberInfo.value = memberInfo;
		};

		// 消息提示音
		const play = () => {
			let audioElement = document.createElement('audio');
			let voice = require('@/assets/voice/msg.mp3');
			audioElement.setAttribute('src', voice);
			audioElement.setAttribute('autoplay', 'autoplay');
		};

		router.beforeEach((to, from, next) => {
			// to and from are both route objects. must call `next`.
			if (from.name == 'service') {
				// proxy.$socket.emit('vendor_change_room', {
				// 	oldMemberId: curMemberInfo.value.oldMemberId,
				// 	memberId: -1,
				// 	...connectBaseData,
				// });

				curMemberInfo.value.memberId = -1;
			}
			next();
		});

		const detectAutoPlay = () => {
			canAutoPlay.audio().then((res) => {
				if (!res.result) {
					ElMessageBox({
						title: L['提示'],
						message: h('div', null, [
							h('p', null, L['您的浏览器没有开启自动播放声音的功能，点击确认后可接收消息提示音。']),
							h('p', null, L['也可以点击浏览器地址前面的图标->网站设置->声音，选择允许，下次可以自动接收消息提示音。']),
						]),
						confirmButtonText: L['确认'],
					}).then(() => {});
				}
			});
		};

		onMounted(() => {
			// TODO【MD】修改IM配置
			if (identity == 'seller') {
				getStoreBaseInfo();
				getAdminInfo(true);
			} else {
				getAdminInfo();
			}
			getVoiceSetting();
			clientWidth.value = document.body.offsetWidth || document.documentElement.offsetWidth;
			window.onbeforeunload = () => {
				proxy.$socket.disconnect();
			};
			detectAutoPlay();
		});

		onUnmounted(() => {
			proxy.$socket.disconnect();
		});

		return {
			imLogo,
			goHome,
			loginOut,
			storeInfo,
			clientWidth,
			L,
			pageClose,
			identity,
			isStoreAdmin,
			curMemberInfo,
			play,
			isAdminSuper,
			adminInfo,
			connectBaseData,
			getCurMember,
			getTitle,
			storeName,
			showBackBtn,
			navLinks,
			socketContact,
		};
	},
};
</script>

<style lang="scss" scoped>
.common-layout {
	height: 100vh;
	.el-container {
		height: 100%;
		background-color: #f7f7f7;
	}
}

.el-aside {
	color: #fff;
	text-align: center;
	background-color: $colorMain;
	padding-top: 3px;
	font-size: 14px;
	position: relative;
	border-right: 1px solid #b8b8b8;
	.aside-title {
		font-size: 14px;
		padding: 22px 10px;
		color: #8d8d99;
		border-bottom: 1px solid #d7d7d7;
		margin-bottom: 20px;
	}
	.sld_left_nav_item {
		color: #000000;
		padding: 0 24px;
		margin: 0 14px;
		line-height: 45px;
		background: $colorMain;
		display: block;
		text-align: left;
		&.router-link-active {
			background: #d7d7d7;
		}
	}
	.aside-foot {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 50px;
	}
}
.el-main {
	max-width: 1315px;
	max-height: 810px;
	padding: 0;
	margin: 0 auto auto;
	overflow: visible;
	&::before {
		content: '';
		display: block;
		height: 10vh;
		min-height: 60px;
		max-height: 120px;
	}
}
</style>
