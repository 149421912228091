<template>
	<div class="container">
		<div class="container_box">
			<div class="top_header">
				<p class="header_title">提示音设置</p>
			</div>
			<div class="content">
				<div class="label">新消息提示音</div>
				<div class="form">
					<el-switch v-model="voiceSwitch" @change="onSubmit"></el-switch>
				</div>
			</div>
		</div>
		<customTable height="300" v-bind="setting_form" v-if="isSuper">
			<template v-slot:top v-if="identity === 'admin'">
				<div class="filter_group">
					<div class="select">
						<el-select class="custom-input" style="width: 300px" v-model="setting_form.filter_params.storeId" placeholder="请选择工作室" filterable>
							<el-option v-for="(item, index) in storeList" :key="index" :label="item.storeName" :value="item.storeId"> </el-option>
						</el-select>
					</div>
				</div>
			</template>
		</customTable>
	</div>
</template>
<script>
import CustomTable from '@/components/CustomTable';
import { getCurrentInstance, reactive, onMounted, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
export default {
	name: 'Setting',
	components: {
		CustomTable,
	},
	setup() {
		const { proxy } = getCurrentInstance();
		const store = useStore();
		const voiceSwitch = ref(false); //表单数据
		const identity = localStorage.identity;
		const storeId = localStorage.storeId;
		const L = proxy.$getCurLanguage();
		const isSuper = Boolean(+localStorage.getItem('isSuper') || +localStorage.getItem('isStoreAdmin'));
		const setting_form = reactive({
			api_url: `v3/helpdesk/${identity}/chat/vendorList`,
			columns: [
				{
					prop: 'sortIndex',
					label: '序号',
					attrs: {
						width: 80,
					},
					type: 'sort',
				},
				{
					prop: 'vendorName',
					label: '客服名称',
				},
				{
					prop: 'isAgent',
					label: '是否为默认接收消息客服',
					type: 'switch',
					attrs: {
						width: 200,
					},
					switch: {
						'active-value': 1,
						'inactive-value': 0,
					},
					callback: async (index, record, value) => {
						const url = `v3/helpdesk/${identity}/chat/storeAgentEdit`;
						const params = {
							storeId: record.storeId,
							vendorId: record.vendorId,
							isAgent: value ? 1 : 0,
						};
						await handleTarget({ url, params });
					},
				},
			],
			filter_params: {
				storeId: identity === 'admin' ? '' : storeId,
			},
			initData: identity === 'admin' ? false : true,
			isPage: false,
			border: true,
			title: '默认客服设置',
			callback: (res) => {
				if (res.data?.length === 0) return;
				const list = res.data.filter((e) => {
					return e.isAgent === 1;
				});
				if (list.length === 0) {
					res.data[res.data.length - 1].isAgent = 1;
				}
			},
		});
		//获取消息提示设置，在用户登录页面写完时将此方法移至登录页面
		const getSetting = () => {
			proxy.$post(`v3/helpdesk/${identity}/sound/setting/isOpen`).then((res) => {
				voiceSwitch.value = res.data.isOpen ? true : false;
				store.commit('updateMsgVoiceSetting', voiceSwitch.value);
			});
		};

		//保存数据
		const onSubmit = (e) => {
			voiceSwitch.value = e;
			let params = {
				isOpen: voiceSwitch.value ? 1 : 0,
			};
			proxy.$post(`v3/helpdesk/${identity}/sound/setting/update`, params).then((res) => {
				ElMessage.success(res.msg);
				store.commit('updateMsgVoiceSetting', voiceSwitch.value);
			});
		};
		const storeList = ref([]); //可选的店铺列表
		const curStore = ref();
		// 获取店铺列表
		const getStoreList = async () => {
			await proxy.$get(`v3/helpdesk/admin/chat/storeList`).then((res) => {
				if (res.state == 200) {
					storeList.value = res.data.filter((e) => e.storeId !== 0);
				}
			});
		};
		// 操作
		const handleTarget = ({ method: method = '$post', url, params }) => {
			return new Promise((resolve, reject) => {
				proxy[method](url, params).then((res) => {
					if (res.state == 200) {
						ElMessage.success(res.msg);
						resolve(res);
					} else {
						ElMessage.error(res.msg);
						reject(false);
					}
				});
			});
		};
		onMounted(() => {
			getSetting();
			identity === 'admin' && getStoreList();
		});
		return {
			onSubmit,
			setting_form,
			voiceSwitch,
			storeList,
			identity,
			isSuper,
		};
	},
};
</script>
<style lang="scss" scoped>
.filter_group {
	padding: 10px;
}
.container {
	width: 820px;
	height: 810px;
	margin: auto;

	.container_box {
		border-radius: 10px;
		background: #fafafa;
		border: 1px solid #b8b8b8;
		box-shadow: 2px 6px 12px 0px rgba(143, 141, 143, 0.3);
		overflow: hidden;
		margin-bottom: 40px;
		.top_header {
			height: 40px;
			line-height: 40px;
			background: #eeeeee;
			padding-left: 23px;
			font-size: 14px;
		}
	}
}
.content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	.label,
	.form {
		flex: 1;
		text-align: center;
	}
	.label {
		color: #767676;
	}
}
</style>
